const routes = {
    ActivateAccountPage: '/activate-account',
    LoginPage: '/login',
    RegisterPage: '/register',
    NotFoundPage: '/404',
    ProfilePage: '/profile',
    ProfileEditPage: '/profile/edit',
    ProfileChangePasswordPage: '/profile/change-password',
    ProfileSettingsPage: '/profile/settings',
    HomePage: '/',
    WallePage: '/wallet',
    WalletFundingPage: '/wallet/funding',

    ReportPage: '/report',
    ReportSmsDetailsPage: '/report/sms-details',
    ReportSmsSummaryPage: '/report/sms-summary',
    ReportScheduledReportPage: '/report/scheduled-report',
    ReportWalletHistoryPage: '/report/wallet-history',
    ReportPaymentHistoryPage: '/report/payment-history',

    AddressBookPage: '/address-book',
    AddressBookAddGroupPage: '/address-book/group/add',
    AddressBookListGroupPage: '/address-book/group/list',

    SMSPage: '/sms',
    SMSSendPage: '/sms/send',
    SMSSendPersonalisedPage: '/sms/send-personalised',

    SenderIDPage: '/sender-id',
    APIDocPage: "/api-doc",
    SettingsPage: '/settings',
};
export default routes;