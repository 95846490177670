/* eslint-disable import/no-unresolved */
import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import { LoginGuard } from 'src/auth/guards';
import DashboardLayout from 'src/layouts/dashboard';

import routes from '../utils/routes';

export const IndexPage = lazy(() => import('src/pages/app'));
export const BlogPage = lazy(() => import('src/pages/blog'));
export const UserPage = lazy(() => import('src/pages/user'));
export const LoginPage = lazy(() => import('src/pages/login'));
export const RegisterPage = lazy(() => import('src/pages/register'));

export const ProfilePage = lazy(() => import('src/pages/profile'));
export const ProfileSettingsPage = lazy(() => import('src/pages/profile-settings'));
export const ProfileEditPage = lazy(() => import('src/pages/profile-edit'));
export const ProfileChangePasswordPage = lazy(() => import('src/pages/profile-change-password'));
export const WalletFundingPage = lazy(() => import('src/pages/wallet-funding'));

export const SettingsPage = lazy(() => import('src/pages/settings'));
export const SenderIDPage = lazy(() => import('src/pages/sender-id'));
export const APIDocPage = lazy(() => import('src/pages/api-doc'));

export const AddressBookAddGroupPage = lazy(() =>
  import('src/pages/address-book/add-address-book-group')
);
export const AddressBookEditGroupPage = lazy(() =>
  import('src/pages/address-book/list-address-book-group')
);

export const SendSMSPage = lazy(() => import('src/pages/send-sms'));
export const SendPersonalisedSMSPage = lazy(() => import('src/pages/send-personalised-sms'));

export const ReportSmsDetailsPage = lazy(() => import('src/pages/report/report-sms-details'));
export const ReportSmsSummaryPage = lazy(() => import('src/pages/report/report-sms-summary'));
export const ReportPaymentHistoryPage = lazy(() =>
  import('src/pages/report/report-payment-history')
);
export const ReportWalletHistoryPage = lazy(() => import('src/pages/report/report-wallet-history'));
export const ReportScheduledReportPage = lazy(() =>
  import('src/pages/report/report-scheduled-report')
);

export const ActivateAccountPage = lazy(() => import('src/pages/activate-account'));
export const ProductsPage = lazy(() => import('src/pages/products'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const Routes = useRoutes([
    {
      element: (
        <LoginGuard>
          <DashboardLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </LoginGuard>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: routes.ProfilePage, element: <ProfilePage /> },
        { path: routes.ProfileSettingsPage, element: <ProfileSettingsPage /> },
        { path: routes.ProfileEditPage, element: <ProfileEditPage /> },
        { path: routes.ProfileChangePasswordPage, element: <ProfileChangePasswordPage /> },
        { path: routes.WalletFundingPage, element: <WalletFundingPage /> },
        { path: routes.ReportSmsDetailsPage, element: <ReportSmsDetailsPage /> },
        { path: routes.ReportSmsSummaryPage, element: <ReportSmsSummaryPage /> },
        { path: routes.ReportScheduledReportPage, element: <ReportScheduledReportPage /> },
        { path: routes.ReportWalletHistoryPage, element: <ReportWalletHistoryPage /> },
        { path: routes.ReportPaymentHistoryPage, element: <ReportPaymentHistoryPage /> },
        { path: routes.SettingsPage, element: <SettingsPage /> },
        { path: routes.SenderIDPage, element: <SenderIDPage /> },
        { path: routes.APIDocPage, element: <APIDocPage /> },
        { path: routes.AddressBookAddGroupPage, element: <AddressBookAddGroupPage /> },
        { path: routes.AddressBookListGroupPage, element: <AddressBookEditGroupPage /> },
        { path: routes.SMSSendPersonalisedPage, element: <SendPersonalisedSMSPage /> },
        { path: routes.SMSSendPage, element: <SendSMSPage /> },
      ],
    },
    {
      path: routes.ActivateAccountPage,
      element: <ActivateAccountPage />,
    },
    {
      path: routes.LoginPage,
      element: <LoginPage />,
    },
    {
      path: routes.RegisterPage,
      element: <RegisterPage />,
    },
    {
      path: routes.NotFoundPage,
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return Routes;
}
