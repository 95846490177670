import axios from 'axios';


export const userHttpClient = axios.create({
    baseURL: import.meta.env.VITE_userModule_BaseURL
});
userHttpClient.interceptors.request.use((req) => {
    if (!req.headers?.Authorization) {
        const username = import.meta.env.VITE_userModule_AuthUser;
        const password = import.meta.env.VITE_userModule_AuthPass;

        req.auth = { username, password };
    }
    return req;
});
