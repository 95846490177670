/* eslint-disable import/no-unresolved */
import WalletIcon from '@mui/icons-material/WalletOutlined';
import AddFundIcon from '@mui/icons-material/AddCardOutlined';
import NoteAltIcon from '@mui/icons-material/NoteAltOutlined';
import TextsmsOutlined from '@mui/icons-material/TextsmsOutlined';
import ThreePOutlined from '@mui/icons-material/ThreePOutlined';
import EditNoteOutlined from '@mui/icons-material/EditNoteOutlined';

import SmsFailedOutlined from '@mui/icons-material/SmsFailedOutlined';
import AccountBalanceWalletOutlined from '@mui/icons-material/AccountBalanceWalletOutlined';
import ReceiptOutlined from '@mui/icons-material/ReceiptOutlined';

import ScheduleSendOutlined from '@mui/icons-material/ScheduleSendOutlined';

import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';

import routes from 'src/utils/routes';

// ----------------------------------------------------------------------

const icon = (name, ext = 'svg') => (
  <SvgColor src={`/assets/icons/navbar/${name}.${ext}`} sx={{ width: 1, height: 1 }} />
);
const navConfig = [
  {
    title: 'dashboard',
    path: routes.HomePage,
    icon: icon('ic_analytics'),
  },
  {
    title: 'SMS',
    path: routes.SMSPage,
    icon: <Iconify icon="eva:message-circle-outline" />,
    items: [
      {
        title: 'Send SMS',
        path: routes.SMSSendPage,
        icon: <TextsmsOutlined />,
      },
      {
        title: 'Personalized SMS',
        path: routes.SMSSendPersonalisedPage,
        icon: <ThreePOutlined />,
      },
    ],
  },
  {
    title: 'Address Book',
    path: routes.AddressBookPage,
    icon: <Iconify icon="eva:book-outline" />,
    items: [
      {
        title: 'Create Group',
        path: routes.AddressBookAddGroupPage,
        icon: <EditNoteOutlined />,
      },
      {
        title: 'View List',
        path: routes.AddressBookListGroupPage,
        icon: <Iconify icon="eva:list-outline" />,
      },
    ],
  },
  {
    title: 'Report',
    path: routes.ReportPage,
    icon: icon('ic_user'),
    items: [
      {
        title: 'SMS Details',
        path: routes.ReportSmsDetailsPage,
        icon: <SmsFailedOutlined />,
      },
      {
        title: 'SMS Summary',
        path: routes.ReportSmsSummaryPage,
        icon: <ReceiptOutlined />,
      },
      {
        title: 'Scheduled Report',
        path: routes.ReportScheduledReportPage,
        icon: <ScheduleSendOutlined />,
      },
      {
        title: 'Wallet History',
        path: routes.ReportWalletHistoryPage,
        icon: <WalletIcon />,
      },
      {
        title: 'Payment History',
        path: routes.ReportPaymentHistoryPage,
        icon: <AccountBalanceWalletOutlined />,
      },
    ],
  },
  {
    title: 'Sender ID',
    path: routes.SenderIDPage,
    icon: <NoteAltIcon />,
  },
  {
    title: 'My Wallet',
    path: routes.WalletMyWalletPage,
    icon: <WalletIcon />,
    items: [
      {
        title: 'Fund Wallet',
        path: routes.WalletFundingPage,
        icon: <AddFundIcon />,
      },
    ],
  },
  {
    title: 'settings',
    path: routes.SettingsPage,
    icon: <Iconify icon="eva:settings-2-outline" />,
  },
  {
    title: 'API-Doc',
    path: routes.APIDocPage,
    icon: <Iconify icon="hugeicons:doc-02" />,
  },
];

export default navConfig;
