import { useContext, useMemo } from 'react';

import { AuthContext } from '../auth-context';
// ----------------------------------------------------------------------
/**
 * 
 * @returns {{ 
 * getFullName:()=>string
 * address: string
 * bearerToken: string
 * createdAt: string
 * updatedAt: string
 * email: string
 * firstName: string
 * lastName: string
 * id: string
 * lastDashboardAccess: string
 * phoneNumber: string
 * userVerified: true
 * }}
 */
export function useAuthenticate() {
    const {account}  = useContext(AuthContext);
  return useMemo(()=>({...account, getFullName(){
    return ([account.firstName,account.lastName]).join(' ')
  }}), [account]);
}

export const useAuthorization = () => useContext(AuthContext);