import axios from 'axios';
// import { getAuthToken } from 'src/auth/services/auth'


export const smsHttpClient = axios.create({
    baseURL: import.meta.env.VITE_smsModule_BaseURL
});

smsHttpClient.interceptors.request.use((req) =>
    // const userTokenResult = await getAuthToken()
    // if(userTokenResult.ok){
    //     req.headers.Authorization = `Bearer ${userTokenResult.data}`
    // }
    req
);
